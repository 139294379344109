import React from 'react'
import { useNavigate } from 'react-router-dom'

function Forgotpassword() {
    const navigate = useNavigate()

    const handleSubmit = () => {
        navigate("/dashboard/analytics")
    }

  return (
    <div className='App'>
    <div className='custom-login'>
        <div className='container'>
            <div className='m-2'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-5'>
                                <div className='custom-inside-log-in'>
                                    <h2>Rempic</h2>
                                    <h6 className='fw-bold'>Forget Password</h6>
                                    <div className='custom-form'>
                                        <form>
                                            <div class="mb-3">
                                                <label for="exampleInputEmail1" class="form-label">Email address</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                            </div>
                                            <div className='d-flex justify-content-between mt-3'>
                                                <p><a href='/'>Back to Login Page</a></p>
                                            </div>
                                            <div className='w-100'>
                                                <button type="submit" class="btn btn-primary w-40" onClick={handleSubmit}>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Forgotpassword