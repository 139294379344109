let config = {};
let environment = "demo";
if (environment == "local") {
  config = {
    frontendurl: "http://localhost:3000/",
    backendurl: "http://localhost:5000/",
    adminBackendUrl: "http://localhost:5000/admin/",

  };
} else if (environment == "demo") {
  config = {
    frontendurl: "https://staging.rempic.com/",
    backendurl: "https://api.rempic.com/",
    adminBackendUrl: "https://stagingadmin.rempic.com/",
   
  };
} 

export default config;

