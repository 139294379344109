import React, { useState } from 'react'
import "../Assets/css/style.css"
import { useNavigate } from 'react-router-dom'
import { useFormik } from "formik"
import * as Yup from "yup"
import { makeApiRequest } from "../axiosService/ApiCall"
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from 'react-bootstrap';

function Login() {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    // const handleSubmit = () => {
    //     navigate("/dashboard/analytics")
    // }

    const toggleOldPasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Enter a valid email").required("Email is required"),
            password: Yup.string("Enter your password").required("Password is required"),
        }),
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('email', values.email);
            formData.append('password', values.password);
            try {
                setIsLoading(true)
                const params = {
                    url: "admin-login",
                    method: "POST",
                    data: formData
                }
                const loginResponse = await makeApiRequest(params)
                console.log('loginResponse', loginResponse)
                if (loginResponse.status == true) {
                    localStorage.setItem("email", values.email)
                    localStorage.setItem("userCredentials", loginResponse.token)
                    setIsLoading(false)
                    toast.success(loginResponse.message)
                    setTimeout(() => {
                        navigate("/dashboard/analytics")
                    }, 3000);
                } else {
                    setIsLoading(false)
                    toast.error(loginResponse.message)
                }
            } catch (error) {
                setIsLoading(false)
                toast.error("Internal Server Error")
            }
        }
    })

    return (
        <div className='App'>
            <ToastContainer />
            <div className='custom-login'>
                <div className='container'>
                    <div className='m-2'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-5'>
                                        <div className='custom-inside-log-in'>
                                            <h2>Rempic</h2>
                                            <h6 className='fw-bold'>Sign In to Application</h6>
                                            <div className='custom-form'>
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div class="mb-3">
                                                        <label for="email" class="form-label">Email address</label>
                                                        <input
                                                            type="email"
                                                            class="form-control"
                                                            id="email"
                                                            aria-describedby="emailHelp"
                                                            value={formik.values.email}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <div className="text-danger rq-msg">{formik.errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                    <div class="mb-3">
                                                        <label for="password" class="form-label">Password</label>
                                                        <input
                                                            type="password"
                                                            class="form-control"
                                                            id="password"
                                                            value={formik.values.password}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.password && formik.errors.password ? (
                                                            <div className="text-danger rq-msg">{formik.errors.password}</div>
                                                        ) : null}
                                                    </div>
                                                    {/* <div class="mb-3 form-check">
                                                        <input 
                                                        type="checkbox" 
                                                        class="form-check-input" 
                                                        id="exampleCheck1" 
                                                        />
                                                        <label class="form-check-label" for="exampleCheck1">Remember Password</label>
                                                    </div> */}

                                                    <div className='w-100'>
                                                        {isLoading ? (
                                                            <button className="d-send-btn-1 rounded-pill" type="button">
                                                                <Spinner animation="border" />
                                                            </button>
                                                        ) : (
                                                            <button type="submit" class="btn btn-primary w-40"  >Submit</button>
                                                        )}
                                                    </div>

                                                    <div className='d-flex justify-content-between mt-3'>
                                                        <p><a href='forgot-password'>Forgot Password</a></p>
                                                        {/* <a href='/register'><p>Register</p></a> */}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login