import React from 'react'
import '../Assets/css/navbar.css'
import { IoMdLogOut } from 'react-icons/io'
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
    const location = useLocation();
    return (
        <div className='App'>
            <div className='container-fluid'>
                <nav class="navbar navbar-expand-lg bg-body-tertiary custom-nav-top-1">
                    <div class="container-fluid">
                        <a class="navbar-brand custom-right-nav-name-1" href="/">Rempic</a>
                        <img src="https://asset.cloudinary.com/dtsmoqlll/9160ef2feee25cad9966b5b3347ba8c4" />
                        <button class="navbar-toggler navbar-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                            <Link class="nav-link" to="/"
                                onClick={() => {
                                    localStorage.clear()
                                }}
                            >
                                <button className='custom-nav-button-1 active' alt="Log Out">
                                    <IoMdLogOut className="sidenav-icon-size-css" />Log Out
                                </button>
                            </Link>

                            {/* <ul class="navbar-nav">
                                <li class="nav-item dashboard-hide">
                                    <a class="nav-link" aria-current="page" href="/login">
                                        <button className='custom-nav-button-1'>Log in</button>
                                    </a>
                                </li>
                                <li class="nav-item dashboard-hide">
                                    <a class="nav-link active" href="#">
                                        <button className='custom-nav-button-1 active' >Sign up</button>
                                    </a>
                                </li>
                               
                               
                            </ul> */}
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Navbar