import React from 'react'
import { useNavigate } from 'react-router-dom'

function Resetpassword() {
    const navigate = useNavigate()

    const handleSubmit = () => {
        navigate("/dashboard/analytics")
    }

    return (
        <div className='App'>
            <div className='custom-login'>
                <div className='container'>
                    <div className='m-2'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-5'>
                                        <div className='custom-inside-log-in'>
                                            <h2>Rempic</h2>
                                            <h6 className='fw-bold'>Reset Password</h6>
                                            <div className='custom-form'>
                                                <form>
                                                    <div class="mb-3">
                                                        <label for="exampleInputPassword1" class="form-label">New Password</label>
                                                        <input type="password" class="form-control" id="exampleInputPassword1" />
                                                    </div>
                                                    <div class="mb-3 form-check">
                                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                                        <label class="form-check-label" for="exampleCheck1">Confirm Password</label>
                                                    </div>
                                                    <div className='w-100'>
                                                        <button type="submit" class="btn btn-primary w-40">Reset</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resetpassword