import './App.css';
import Dashboard from './seperate/Dashboard';
import { BrowserRouter, Route, Routes , Navigate} from 'react-router-dom';

import Login from './seperate/Login';
import Forgotpassword from './seperate/Forgotpassword';
import Resetpassword from './seperate/Resetpassword';
import Register from './seperate/Register';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
     
      <Routes>
        {/* Redirect from "/" to "/dashboard/analytics" */}
        {/* <Route path="/" element={<Navigate to="/dashboard/analytics" replace />} /> */}
        <Route exact path="/" element={<Login />} />
        <Route exact path="/forgot-password" element={<Forgotpassword />} />
        <Route exact path="/reset-password" element={<Resetpassword />} />
        <Route exact path="/register" element={<Register />} />
        <Route path="/dashboard/analytics" element={<Dashboard />} />
        <Route path="/dashboard/home" element={<Dashboard />} />

      </Routes>
      
    </BrowserRouter>
    </div>
  );
}

export default App;
